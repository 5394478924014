.join{
    display: flex;
    padding: 0 2rem;
    grid:10rem ;
}
.Left-j{
    color: white;
    font-size: 3rem;
    text-transform: uppercase;
    position: relative;
}
.Left-j>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0;
}
.Left-j>div{
display: flex;
gap: 1rem;
}
.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-conatainer{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
    margin-left: 10rem;
}
.email-conatainer>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
}
::placeholder{
    color: var(--lightgray);
}
.btn-j{
    background-color: var(--orange);
    color: white;
}
@media screen and (max-width:786px)
{
    .join{
        flex-direction: column;
        gap: 1rem;
    }
    .Left-j{
        font-size: x-large;
        flex-direction: column;
    }
    .email-conatainer{
        margin-left: 0;
    }
    .right-j{
        padding: 2rem;
    }
}